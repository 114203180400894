import { Row, Col } from "react-bootstrap";
import Map from "../../assets/images/map.png";
import { motion } from "framer-motion";
function Block5() {
  return (
    <>
      <div className="type3-Block5Box1">
        <Row className="g-0">
          <Col md={6}>
            <div className="type3-Block5Box2">
              <div className="type3-Block5Box3">
                <img className="type3-Block5Img" src={Map} alt="" />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="type3-Block5Box4">
              <div className="type3-Block5Box5">
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.9,
                    // type: "spring",
                    // stiffness: 150,
                  }}
                  className="type3-Block5Txt1"
                >
                  {" "}
                  The Global Presence
                </motion.div>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.8,
                    // type: "spring",
                    // stiffness: 150,
                  }}
                  className="type3-Block5Txt2"
                >
                  Our highly skilled team makes every effort to provide
                  excellent customer service, offer products with minimal waste,
                  and eliminate all market risks
                </motion.div>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.7,
                    // type: "spring",
                    // stiffness: 150,
                  }}
                  className="type3-Block5Txt3"
                >
                  Our Presence in United Arab Emirates
                </motion.div>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.6,
                    // type: "spring",
                    // stiffness: 150,
                  }}
                  className="type3-Block5Txt4"
                >
                  Office- 16 K, Gold Tower,
                  <br />
                  Cluster-I, Jumeirah Lake Towers 
                  <br />P.O.BOX: 643949 Dubai-UAE
                  <br />
                  Tel: +971 4 585 0978
                  <br />
                  Email: admindubai@arkanmars.com.com <br />{" "}
                </motion.div>
                <motion.button
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.5,
                    // type: "spring",
                    // stiffness: 150,
                  }}
                  className="type3-Block5Btn"
                >
                  SEND US AN EMAIL
                </motion.button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Block5;
